import 'normalize.css'
import { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

import '../fonts.css'

const GlobalStyle = createGlobalStyle`

  body {
    font-family: 'Roboto', sans-serif;
    color: #222;
    background: ${rgba('#E2EDEE', 0.1)};

    // Hotjar styles
    /* Apply styles to the first div inside dialog */
    @media (min-width: 1024px) {
    dialog > div:nth-child(1) {
      width: 650px !important;
      height: 450px;
      font-size: 1.5rem;
      padding: 2rem !important;
    }
  }
  }
`

export default GlobalStyle
